<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="min-h-screen pt-16 pb-12 flex flex-col bg-white">
    <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex-shrink-0 flex justify-center">
        <a href="/" class="inline-flex">
          <span class="sr-only">Diamond Hands Hotel</span>
          <span class="text-3xl lg:text-5xl">💎&nbsp;🙌&nbsp;🏨</span>
        </a>
      </div>
      <div class="py-16">
        <div class="text-center">
          <p class="text-sm font-semibold text-pink-600 uppercase tracking-wide">The Roof</p>
          <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Sorry, we're at capacity
          </h1>
          <p class="mt-2 text-base text-gray-500">
            You can only access The Roof with a Diamond Hands Hotel NFT
          </p>
          <div class="mt-6">
            <a href="https://diamondhandshotel.com/drops" class="text-base font-medium text-pink-600 hover:text-pink-500">
              See our collections<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </main>
    <footer class="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="flex justify-center space-x-4">
        <a href="https://twitter.com/diamondhh" class="text-sm font-medium text-gray-500 hover:text-gray-600">Twitter</a>
        <span class="inline-block border-l border-gray-300" aria-hidden="true" />
        <a href="https://discord.gg/rt7Eemzyev" class="text-sm font-medium text-gray-500 hover:text-gray-600">Discord</a>
        <span class="inline-block border-l border-gray-300" aria-hidden="true" />
        <a href="mailto:frontdesk@diamondhandshotel.com" class="text-sm font-medium text-gray-500 hover:text-gray-600">Contact</a>
      </nav>
    </footer>
  </div>
</template>
